import "typeface-montserrat"
import React from "react"
import { Global, css } from "@emotion/core"
import Header from "./headerElMirage"
import Footer from "./footerElMirage"
import HeroElMirage from "./heroElMirage"
import LandingBar from "./timeandlocationElMirage"
import "../../node_modules/react-modal-video/scss/modal-video.scss"

const Layout = ({ children }) => (
    <>
        <Global
            styles={css`
        * {
          box-sizing: border-box;
          margin: 0;
        }
        a {
          color: #312e2e;
          font-weight: 700;
        }
        html,
        body {
          margin: 0;
          color: #312e2e;
          font-family: -apple-system, BlinkMacSystemFont, "Montserrat",
            "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
          font-size: 14px;
          line-height: 1.4;
          background-color: #f7fafc;
          / *remove margin for the main div that Gatsby mounts into */ > div {
            margin-top: 0;
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: #312e2e;
              line-height: 1.1;
              + * {
                margin-top: 0.5rem;
              }
            }
            strong {
              color: #312e2e;
            }
            li {
              margin-top: 0.25rem;
            }
          }
        }
      `}
        />
        <Header />
        <div
            css={css`
        display: flex;
        min-height: 100vh;
        flex-direction: column;
      `}
        >
            <HeroElMirage />
            <LandingBar />
            <main
                css={css`
          margin: 2rem auto 4rem;
          max-width: 90vw;
          width: 1050px;
          flex-grow: 1;
        `}
            >
                {children}
            </main>
            <Footer />
        </div>
    </>
)

export default Layout
